import {
  reactive,
  ref,
  toRefs,
  computed,
  onMounted
} from "@vue/composition-api";
import axios from "axios";
import helpers from "@/helpers";
import config from "@/config";
import store from "@/store";

export default (_, { root }) => {
  const subLibraryId = root._route.params.id;
  const state = store.settings;
  const mainLayout = store.mainLayout;
  const mlState = subLibraryId ? state.subLibraryState : mainLayout.state;
  const logoFileInput = ref(null);

  const isSubLibrary = computed(() =>
    store.mainLayout?.state?.libraryParent ? true : false
  );

  const loadMemberClasses = () => {
    axios
      .get(`${process.env.VUE_APP_API}/member/membershipPlan`)
      .then((response) => {
        state.membershipPlans = response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  if (subLibraryId) {
    state.subLibraryState = { sublibrary: true };
    helpers.getLibraryData(null, subLibraryId, mlState, () => {
      root._router.push("/404");
    });
  }

  const expired = computed(() => mainLayout.isExpired);

  const expiringSoon = computed(() => mainLayout.isExpiringSoon);

  const validate = (settingsKey) => {
    let value = state["new" + helpers.string.pascalCase(settingsKey)];
    if (!value) {
      return false;
    }
    value = value.trim();
    switch (settingsKey) {
      case "name":
        return value.length >= 5 && value.length <= 75;
      case "email":
        return helpers.string.validateEmail(value) && value.length <= 100
          ? true
          : false;
      case "address":
        return value.length >= 1 && value.length <= 300;
      case "regNo":
        return value.length >= 1 && value.length <= 25;
      case "pin":
        return value.length === 6;
      case "bypassSignPasscode":
        return value.length === 4;
      case "phone":
        return value.length >= 10;
    }
    return true;
  };

  const updateSettingsByKey = (settingsKey) => {
    updateSettings({
      settingsKey,
      value: state["new" + helpers.string.pascalCase(settingsKey)].trim(),
      onSuccess: () => {
        mlState["library" + helpers.string.pascalCase(settingsKey)] =
          state["new" + helpers.string.pascalCase(settingsKey)];
        state["edit" + helpers.string.pascalCase(settingsKey)] = false;
        store.app.showAlert("Updated successfully!");
      }
    });
  };

  const updateSettings = ({ settingsKey, value, onSuccess }) => {
    const data = {};
    if (state[settingsKey + "Loading"]) {
      return;
    }
    data[settingsKey] = value;
    state[settingsKey + "Loading"] = true;
    axios
      .patch(
        `${process.env.VUE_APP_API}/libraryBasicData/${
          subLibraryId ? subLibraryId : ""
        }`,
        data
      )
      .then((response) => {
        onSuccess && onSuccess(response);
        if (subLibraryId) {
          store.subLibraries.state.resetData();
        }
      })
      .catch((response) => {
        if (response?.response?.data?.emailExists) {
          store.app.showAlert("Email already exist");
        } else {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => (state[settingsKey + "Loading"] = false));
  };

  const photoAdded = (file) => {
    logoFileInput.value.$el.querySelector("input").value = null;
    helpers.getBase64FromFile(file, (data) => {
      state.logoCropperSrc = data;
      state.showLogoDialog = true;
    });
  };
  const logoSelect = () => {
    logoFileInput.value.$el.querySelector("input").click();
  };
  const updateLogo = (data) => {
    state.showLogoDialog = false;
    updateSettings({
      settingsKey: "logo",
      value: data,
      onSuccess: () => {
        mlState.libraryHasLogo = true;
        mlState.libraryLogoUrl = mlState.libraryLogoUrl + "&r=" + Math.random();
      }
    });
  };
  const deleteLogo = () => {
    updateSettings({
      settingsKey: "logo",
      value: false,
      onSuccess: () => {
        store.mainLayout.state.libraryHasLogo = false;
        mlState.libraryHasLogo = false;
      }
    });
  };
  const confirmDeleteLogo = () => {
    store.app.showConfirm({
      title: "Are you sure?",
      content: `Are you sure to remove log of your library?`,
      type: "warning",
      cancel: () => store.app.hideConfirm(),
      callback: (a) => {
        deleteLogo();
      }
    });
  };

  const cancelPasswordChange = () => {
    state.confirmForm.reset();
    state.showPasswordDialog = false;
  };

  const passwordValidation = (pwd) => {
    if (!pwd || pwd.trim().length === 0) {
      return "Required";
    }
    if (!helpers.string.passwordCriteriaMet(pwd)) {
      return "Password must have uppercase, lowercase, digit, symbol and make sure the length is between 8 and 20";
    }
    return true;
  };

  const confirmPasswordValidation = (pwd) => {
    if (!pwd || pwd.trim().length === 0) {
      return "Required";
    }
    if (state.newPassword !== state.newPasswordConfirm) {
      return "Password doesnt match";
    }
    return true;
  };

  const updatePassword = () => {
    if (!state.confirmForm.validate()) {
      return;
    }
    store.app.showLoader("Please wait...");
    axios
      .put(
        `${process.env.VUE_APP_API}/password/${
          subLibraryId ? subLibraryId : ""
        }`,
        {
          currentPassword: state.currentPassword,
          newPassword: state.newPassword,
          newPasswordConfirm: state.newPasswordConfirm
        }
      )
      .then(() => {
        store.app.showDialog("Success!", "Password changed successfully!");
        cancelPasswordChange();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          store.app.showDialog(
            "Error!",
            "Current password you entered was incorrect",
            "error"
          );
        } else {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => store.app.hideLoader());
  };

  const saveMemberShipPlan = () => {
    if (!state.planForm.validate()) {
      return;
    }
    store.app.showLoader("Saving...");
    axios[state.editMembershipPlan._id ? "put" : "post"](
      `${process.env.VUE_APP_API}/member/membershipPlan/${
        state.editMembershipPlan._id || ""
      }`,
      state.editMembershipPlan
    )
      .then(() => {
        store.app.showDialog("Success!", "Membership plan has been saved!");
        loadMemberClasses();
        state.showMembershipPlan = false;
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          store.app.showDialog(
            "Cannot do this action!",
            error?.response?.data?.message,
            "error"
          );
        } else {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => store.app.hideLoader());
  };

  const deleteMemberShipPlan = (id, confirm = false) => {
    if (!confirm) {
      store.app.showConfirm({
        okText: "Confirm",
        title: "Are you sure?",
        content: "Are you sure to delete this Membership Plan?",
        type: "warning",
        callback: (a) => {
          deleteMemberShipPlan(id, true);
        },
        cancel: () => {}
      });
      return;
    }
    store.app.showLoader("Deleting...");
    axios
      .delete(`${process.env.VUE_APP_API}/member/membershipPlan/${id}`)
      .then(() => {
        store.app.showDialog("Deleted!", "Membership plan has been deleted!");
        loadMemberClasses();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          store.app.showDialog(
            "Cannot delete!",
            error?.response?.data?.message,
            "error"
          );
        } else {
          store.app.showAlert("Some error occured!");
        }
      })
      .finally(() => store.app.hideLoader());
  };

  onMounted(() => {
    loadMemberClasses();
  });

  return {
    ...toRefs(state),
    photoAdded,
    expired,
    expiringSoon,
    validate,
    logoSelect,
    passwordValidation,
    confirmPasswordValidation,
    cancelPasswordChange,
    updatePassword,
    updateLogo,
    updateSettingsByKey,
    saveMemberShipPlan,
    deleteMemberShipPlan,
    confirmDeleteLogo,
    isSubLibrary,
    logoFileInput,
    districts: config.districts,
    mlState: reactive(mlState)
  };
};
